import TableContainer from "@mui/material/TableContainer";
import { IGetAllTripRsp, ITrip } from "../../api/service.type";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { getMessage } from "../../utils/MessageUtils";
import { useAlert } from "../../components/useAlert";
import { useConfirm } from "material-ui-confirm";
import { shuttleService } from "../../api/service";

type Props = {
    data?: IGetAllTripRsp,
    openEditDialog: () => void;
    handleReload: () => void;
    handleEdit: (driver: ITrip) => void;
}

const TripList = ({ data, openEditDialog, handleReload, handleEdit }: Props) => {
    const { showAlert } = useAlert();
    const confirm = useConfirm();
    const handleDelete = (id: number) => {
        confirm({ title: '是否删除', confirmationText: '确定', cancellationText: '取消', description: '该行程数据将被删除' })
            .then(() => {
                // 删除记录
                shuttleService.deleteTrip({
                    id: id
                }).then(({ code, message }) => {
                    if (code === 0) {
                        // 重新加载
                        handleReload();
                    } else {
                        showAlert("删除失败" + message, 'error')
                    }
                }).catch((err) => {
                    showAlert("删除失败" + err, 'error')
                })
            }).catch(() => { });
    }

    return (data && data.list.length > 0 ?
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">机场</TableCell>
                        <TableCell align="center">城市</TableCell>
                        <TableCell align="center">车程时长</TableCell>
                        <TableCell align="center">预付款价/人</TableCell>
                        <TableCell align="center">一人成行尾款价/人</TableCell>
                        <TableCell align="center">二人成行尾款价/人</TableCell>
                        <TableCell align="center">三人成行尾款价/人</TableCell>
                        <TableCell align="center">操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.list.map((trip) => (
                        <TableRow
                            key={trip.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {getMessage(trip.airport, 'Airport')}
                            </TableCell>
                            <TableCell align="center">{getMessage(trip.city, 'City')}</TableCell>
                            <TableCell align="center">{trip.duration}min</TableCell>
                            <TableCell align="center">{trip.adv_price}元</TableCell>
                            <TableCell align="center">{trip.one_bal_price}元</TableCell>
                            <TableCell align="center">{trip.two_bal_price}元</TableCell>
                            <TableCell align="center">{trip.three_bal_price}元</TableCell>
                            <TableCell align="center">
                                <Button variant="text" onClick={() => {
                                    handleEdit({ ...trip });
                                    openEditDialog();
                                }}>修改</Button>
                                <Button variant="text" onClick={() => handleDelete(trip.id)}>删除</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        :
        <div>暂无数据</div>)
}

export default TripList;