import TableContainer from "@mui/material/TableContainer";
import { IDateArrangement, IDriver, IOrder, IOrderVo } from "../../api/service.type";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import { getMessage } from "../../utils/MessageUtils";
import { useLayoutEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import DispatchCarDialog from "./DispatchCarDialog";
import OrderInfoDialog from "./OrderInfoDialog";

// 用于订单组合分配车辆颜色
const cellColors = new Map<number, string>([
    [1, '#f53d3d'],
    [2, '#f77a05'],
    [3, '#ede655'],
    [4, '#54cc04'],
    [5, '#04ccae'],
    [6, '#0593e6'],
    [7, '#4153f2'],
    [8, '#a541f2'],
    [9, '#f274df'],
    [10, '#c7c3c3'],
]);

type Props = {
    orders: IOrderVo[],
    arrangements: IDateArrangement[],
    airport: string,
    city: string,
    useType: string,
    drivers: IDriver[],
    handleUpdate: () => void
}

const OrderTable = ({ orders, arrangements, airport, city, useType, drivers, handleUpdate }: Props) => {
    const [openDispatch, setOpenDispatch] = useState<boolean>(false);
    // key为order_id, value为tuple形式: [arrangement_id, passenger_num]
    const [selectedOrder, setSelectedOrder] = useState<Map<string, [number, number]>>(new Map<string, [number, number]>([]));
    const [dispatchIndexMap, setDispatchIndexMap] = useState<Map<number, number>>(new Map<number, number>([]));
    const [openOrderInfo, setOpenOrderInfo] = useState<boolean>(false);
    const [orderInfo, setOrderInfo] = useState<IOrderVo>();

    useLayoutEffect(() => {
        let map = new Map<number, number>([]);
        cellColors.forEach((_value, key) => {
            map.set(key, 0);
        })
        orders.forEach((order) => {
            if (order.order_info.dispatch_index && map.has(order.order_info.dispatch_index)) {
                map.set(order.order_info.dispatch_index, (map.get(order.order_info.dispatch_index) || 0) + 1);
            }
        });
        setDispatchIndexMap(new Map(map));
    }, [orders]);

    let scheduleName;
    if (useType === 'Send') {
        scheduleName = getMessage(city, 'City') + ' --> ' + getMessage(airport, 'Airport')
    } else {
        scheduleName = getMessage(airport, 'Airport') + ' --> ' + getMessage(city, 'City')
    }

    const handleClickBlock = (order: IOrder, arrangementId: number) => {
        let orderArrMap = selectedOrder;
        if (orderArrMap.has(order.order_id)) {
            if (orderArrMap.get(order.order_id)?.[0] === arrangementId) {
                orderArrMap.delete(order.order_id);
                setSelectedOrder(new Map(orderArrMap));
            }
        } else {
            if (orderArrMap.size === 0 || orderArrMap.values().next().value?.[0] === arrangementId) {
                orderArrMap.set(order.order_id, [arrangementId, order.passenger_num]);
                setSelectedOrder(new Map(orderArrMap));
            }
        }
    }

    const handleDispatch = () => {
        setOpenDispatch(true);
    }

    const handleRemove = () => {
        let orderArrMap = new Map<string, [number, number]>([]);
        setSelectedOrder(orderArrMap);
    }

    const closeDispatchDialog = () => {
        setOpenDispatch(false);
    };

    const handleDispatched = () => {
        setSelectedOrder(new Map([]))
        handleUpdate()
    }

    const findMinOccurDispatchIndex = (): number => {
        let minOccur: number = Number.MAX_VALUE;
        let finalDispatchId = 1;
        dispatchIndexMap.forEach((count, dispatchId) => {
            if (count < minOccur) {
                minOccur = count;
                finalDispatchId = dispatchId;
            }
        });
        return finalDispatchId;
    };

    const handleOrderInfo = (order: IOrderVo) => {
        setOrderInfo(order);
        setOpenOrderInfo(true);
    }

    const handleCloseOrderInfo = () => {
        setOpenOrderInfo(false);
    }

    return (
        <div>
            <Stack direction='row' spacing={2} paddingTop={2} paddingBottom={0}>
                <Button disabled={selectedOrder.size === 0} onClick={handleDispatch}>车辆安排</Button>
                <Button disabled={selectedOrder.size === 0} onClick={handleRemove}>取消选定</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" rowSpan={2}>订单ID</TableCell>
                            <TableCell align="center" colSpan={arrangements.length}>{scheduleName}</TableCell>
                            <TableCell align="center" rowSpan={2}>预付款</TableCell>
                            <TableCell align="center" rowSpan={2}>车次安排</TableCell>
                            <TableCell align="center" rowSpan={2}>尾款</TableCell>
                            <TableCell align="center" rowSpan={2}>司机安排</TableCell>
                            <TableCell align="center" rowSpan={2}>订单状态</TableCell>
                            <TableCell align="center" rowSpan={2}>操作</TableCell>
                        </TableRow>
                        <TableRow>
                            {arrangements.map((arr) => (
                                <TableCell
                                    key={arr.arrangement_id}
                                    align="center"
                                >
                                    {arr.depart_time}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => {
                            let dispatchedDriver = drivers.find((driver) => driver.id === order.order_info.driver_id);
                            return (
                                <TableRow key={order.order_info.order_id}>
                                    <TableCell align="center" component="th" scope="row">
                                        {order.order_info.order_id}
                                    </TableCell>
                                    {arrangements.map((arr) => {
                                        if (order.arrangements.find((owned) => owned.arrangement_id === arr.arrangement_id)) {
                                            // TODO: 前端是否要维护订单状态枚举值？
                                            if (order.order_info.status !== 'AdvancePaid') {
                                                return (
                                                    <TableCell
                                                        key={arr.arrangement_id}
                                                        align="center"
                                                    >
                                                        {order.order_info.passenger_num}
                                                    </TableCell>
                                                )
                                            } else {
                                                let borderStyle = selectedOrder.has(order.order_info.order_id) && selectedOrder.get(order.order_info.order_id)?.[0] === arr.arrangement_id ? 'thick double #32a1ce' : 'none';
                                                let background = order.order_info.dispatch_index
                                                    && order.order_info.arrangement_id
                                                    && order.order_info.arrangement_id === arr.arrangement_id ?
                                                    cellColors.get(order.order_info.dispatch_index) : null;
                                                return (
                                                    <TableCell
                                                        key={arr.arrangement_id}
                                                        align="center"
                                                        sx={{ cursor: 'pointer', border: borderStyle, background: background }}
                                                        onClick={() => handleClickBlock(order.order_info, arr.arrangement_id)}
                                                    >
                                                        {order.order_info.passenger_num}
                                                    </TableCell>
                                                )
                                            }
                                        } else {
                                            return (
                                                <TableCell key={arr.arrangement_id} align="center">-</TableCell>
                                            )
                                        }
                                    })}
                                    <TableCell align="center">
                                        {order.order_info.advance_payment}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dispatchedDriver && (dispatchedDriver.capacity + '座_' + order.order_info.dispatch_index)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.order_info.balance_payment}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dispatchedDriver && (dispatchedDriver.name + '_' + dispatchedDriver.license_plate_number)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {getMessage(order.order_info.status, 'OrderStatus')}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="text" >确认</Button>
                                        <Button variant="text" >退款</Button>
                                        <Button variant="text" onClick={() => handleOrderInfo(order)}>详情</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <DispatchCarDialog
                isOpen={openDispatch}
                data={selectedOrder}
                drivers={drivers}
                dispatch_index={findMinOccurDispatchIndex()}
                handleClose={closeDispatchDialog}
                handleDispatched={handleDispatched}
            />
            <OrderInfoDialog
                isOpen={openOrderInfo}
                order={orderInfo}
                drivers={drivers}
                scheduleName={scheduleName}
                handleClose={handleCloseOrderInfo}
            />
        </div>
    )
}

export default OrderTable;