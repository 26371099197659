import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme } from "@mui/material";
import { shuttleService } from "../../api/service";
import { useAlert } from "../../components/useAlert";

const LoginIn = () => {
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    useLayoutEffect(() => {
        const userId = localStorage.getItem("username");
        if (userId) {
            navigate("/order");
        }
    })

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username')
        const password = data.get('password')
        if (username && password) {
            shuttleService.login({
                username: String(username),
                password: String(password)
            }).then(({ code, data, message }) => {
                if (code !== 0 || !data) {
                    showAlert('登录失败: ' + message, 'error')
                } else {
                    localStorage.setItem("username", String(data.username));
                    navigate("/")
                }
            }).catch((err) => {
                showAlert('登录失败: ' + err, 'error')
            })
        } else {
            showAlert('用户名和密码均必填', 'error')
        }
    };

    return (
        <ThemeProvider theme={createTheme()}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ marginBottom: 5 }}>
                        接送机预定管理系统
                    </Typography>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign In
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="用户名"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="密码"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            登录
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default LoginIn;
