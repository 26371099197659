import axios, { AxiosError, AxiosInstance } from "axios";
import { ApiResponse, IRequestConfig } from "./type";

export interface IApiClient {
    post<TRequest, TResponse>(path: string, object?: TRequest, config?: IRequestConfig): Promise<ApiResponse<TResponse>>;
    patch<TRequest, TResponse>(path: string, object?: TRequest): Promise<ApiResponse<TResponse>>;
    put<TRequest, TResponse>(path: string, object?: TRequest): Promise<ApiResponse<TResponse>>;
    get<TRequest, TResponse>(path: string, params?: TRequest): Promise<ApiResponse<TResponse>>;
}

export class ApiConfig {
    endpoint = '';
    headers?: {};
}

const DEFAULT_ERROR_RESPONSE = {
    code: -1,
    message: '网络通讯失败',
    data: null
}

export default class ApiClient implements IApiClient {
    protected client: AxiosInstance;

    protected createAxiosClient(apiConfig: ApiConfig): AxiosInstance {
        let client = axios.create({
            baseURL: "/",
            responseType: 'json' as const,
            headers: {
                'Content-Type': 'application/json',
                ...apiConfig.headers
            },
            timeout: 10 * 1000
        });
        client.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("username");
                    // 重定向至登录页
                    window.location.assign('/login')
                }
                // this.handleApiError(error);
                return Promise.reject(error);
            }
        )
        return client;
    }

    constructor(apiConfig: ApiConfig) {
        this.client = this.createAxiosClient(apiConfig);
    }

    async post<TResponse>(
        path: string,
        payload?: any,
        config?: IRequestConfig
    ): Promise<ApiResponse<TResponse>> {
        let response = config
            ? await this.client.post<ApiResponse<TResponse>>(path, payload, config)
            : await this.client.post<ApiResponse<TResponse>>(path, payload);
        return response.data;
    }

    async patch<TRequest, TResponse>(path: string, payload: TRequest): Promise<ApiResponse<TResponse>> {
        const response = await this.client.patch<ApiResponse<TResponse>>(path, payload);
        return response.data;
    }

    async put<TRequest, TResponse>(path: string, payload: TRequest): Promise<ApiResponse<TResponse>> {
        const response = await this.client.put<ApiResponse<TResponse>>(path, payload);
        return response.data;
    }

    async get<TResponse>(path: string, params?: any): Promise<ApiResponse<TResponse>> {
        const response = await this.client.get<ApiResponse<TResponse>>(path, {
            params
        });
        return response.data;
    }

    protected handleApiError(error: AxiosError) {
        if (error.response) {
            const { response } = error;
            alert(`网络通讯失败，状态码: ${response.status}`)
            // return message.error(`网络通讯失败，状态码: ${response.status}`);
        }
        alert(`网络通讯失败: ${error.message || '未知原因'}`)
        // return message.error(`网络通讯失败: ${error.message || '未知原因'}`);
    }
}