import ApiClient, { ApiConfig } from "./core/apiClient";
import {
    IAccountModifyReq,
    IAddDriverReq,
    IAddDriverRsp,
    IAddScheduleReq,
    IAddScheduleRsq,
    IAddTripReq,
    IAddTripRsp,
    IDeleteDriverReq,
    IDeleteScheduleReq,
    IDeleteTripReq,
    IDispatchOrderReq,
    IGetAllDriverRsp,
    IGetAllScheduleRsp,
    IGetAllTripRsp,
    IGetArrangementsReq,
    IGetArrangementsRsp,
    IGetDailyOrdersReq,
    IGetDailyOrdersRsp,
    IGetTripsByAirportReq,
    IGetTripsByAirportRsp,
    ILoginReq,
    ILoginRsp,
    IOrderArrangement,
    IStatusRsp,
    IUpdateDriverReq,
    IUpdateDriverRsp,
    IUpdateTripReq,
    IUpdateTripRsp
} from "./service.type";

export class ShuttleApiConfig extends ApiConfig { }

export class ShuttleApiClient extends ApiClient {
    constructor(apiConfig: ApiConfig) {
        super(apiConfig);
        this.initInterceptors();
    }

    private initInterceptors() { }
}

export class ShuttleApiService {
    constructor(private apiClient: ShuttleApiClient) { }
    // 账号登录
    login = (params: ILoginReq) => this.apiClient.post<ILoginRsp>('/login', params);
    // 账号登出
    logout = () => this.apiClient.post<null>('/logout');
    // 账号修改
    modifyAccount = (params: IAccountModifyReq) => this.apiClient.post<null>('/account/modify', params);
    // 状态查询
    status = () => this.apiClient.get<IStatusRsp>('/status');

    // 新增司机
    addDriver = (params: IAddDriverReq) => this.apiClient.post<IAddDriverRsp>('/driver/add', params);
    // 获取全部司机信息
    getAllDrivers = () => this.apiClient.get<IGetAllDriverRsp>('/driver/all');
    // 删除司机
    deleteDriver = (params: IDeleteDriverReq) => this.apiClient.post<null>('/driver/delete', params);
    // 更新司机
    updateDriver = (params: IUpdateDriverReq) => this.apiClient.post<IUpdateDriverRsp>('/driver/update', params);

    // 新增行程
    addTrip = (params: IAddTripReq) => this.apiClient.post<IAddTripRsp>('/trip/add', params);
    // 获取全部行程信息
    getAllTrips = () => this.apiClient.get<IGetAllTripRsp>('/trip/all');
    // 删除行程
    deleteTrip = (params: IDeleteTripReq) => this.apiClient.post<null>('/trip/delete', params);
    // 更新行程
    updateTrip = (params: IUpdateTripReq) => this.apiClient.post<IUpdateTripRsp>('/trip/update', params);
    // 查询指定机场的全部行程
    getTripsByAirport = (params: IGetTripsByAirportReq) => this.apiClient.get<IGetTripsByAirportRsp>('/trip/airport', params);

    // 新增车次
    addSchedule = (params: IAddScheduleReq) => this.apiClient.post<IAddScheduleRsq>('/schedule/add', params);
    // 获取全部车次信息
    getAllSchedules = () => this.apiClient.get<IGetAllScheduleRsp>('/schedule/all');
    // 删除车次
    deleteSchedule = (params: IDeleteScheduleReq) => this.apiClient.post<null>('/schedule/delete', params);

    // 获取指定日期的预约需求安排情况
    getArrangements = (params: IGetArrangementsReq) => this.apiClient.get<IGetArrangementsRsp>('/order/arrangements', params);
    // 获取指定日期指定机场和城市的全部订单情况
    getDailyOrders = (params: IGetDailyOrdersReq) => this.apiClient.get<IGetDailyOrdersRsp>('/order/daily-orders', params);
    // 给订单分配车辆信息
    dispatchOrders = (params: IDispatchOrderReq) => this.apiClient.post<null>('/order/dispatch', params);
}

// init service
const shuttleService = new ShuttleApiService(new ShuttleApiClient(new ApiConfig()));

export { shuttleService };