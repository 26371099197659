import { Outlet } from "react-router-dom";
import LeftDrawer from "../../components/LeftDrawer";

export default function NavSideBar() {
    return (
        <div>
            <LeftDrawer />
            <div style={{ marginLeft: 200 }}>
                <Outlet />
            </div>
        </div>
    )
}