import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { getMessage } from "../../utils/MessageUtils";
import { IDriver, IOrderVo } from "../../api/service.type";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";

type Props = {
    isOpen: boolean;
    order?: IOrderVo;
    drivers: IDriver[];
    scheduleName: string;
    handleClose: () => void;
};

const OrderInfoDialog = ({ isOpen, order, drivers, scheduleName, handleClose }: Props) => {
    if (!order) {
        return (<></>);
    }
    let driver = drivers.find((driver) => driver.id === order.order_info.driver_id);
    let arrangementInfo = order.arrangements.find((arr) => arr.arrangement_id === order.order_info.arrangement_id);
    return (
        <Fragment>
            <Dialog
                open={isOpen}
                fullWidth={true}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">订单信息</DialogTitle>
                <DialogContent dividers={true}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' >
                        <Typography variant='h6' gutterBottom>
                            基本信息
                        </Typography>
                        {/* <Typography variant='body1' gutterBottom>
                            {getMessage(order.order_info.status, 'OrderStatus')}
                        </Typography> */}
                        <Box sx={{ p: 1, border: '1px solid red' }}>
                            {getMessage(order.order_info.status, 'OrderStatus')}
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant='body1' gutterBottom>
                            订单ID: {order.order_info.order_id}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            预定者名称: {order.order_info.booker_name}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            预定者联系方式: {order.order_info.booker_mobile_phone}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            乘客联系方式: {order.order_info.passenger_mobile_phone}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            乘客人数: {order.order_info.passenger_num}
                        </Typography>
                    </Stack>
                    <Divider variant='fullWidth' light />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' paddingTop={2}>
                        <Typography variant='h6' gutterBottom>
                            预付款
                        </Typography>
                        <Box sx={{ p: 1, border: '1px solid red' }}>
                            {order.order_info.advance_complete_time ? '已付款' : '未完成'}
                        </Box>
                    </Stack>
                    <Stack paddingBottom={1}>
                        <Typography variant='body1' gutterBottom>
                            预付款金额: {order.order_info.advance_payment}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            预付款微信订单ID: {order.order_info.advance_wx_order_id}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            预付款完成时间: {order.order_info.advance_complete_time}
                        </Typography>
                    </Stack>
                    <Divider variant='fullWidth' light />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' paddingTop={2}>
                        <Typography variant='h6' gutterBottom>
                            尾款
                        </Typography>
                        <Box sx={{ p: 1, border: '1px solid red' }}>
                            {order.order_info.balance_complete_time ? '已付款' : '未完成'}
                        </Box>
                    </Stack>
                    <Stack paddingBottom={1}>
                        <Typography variant='body1' gutterBottom>
                            尾款金额: {order.order_info.balance_payment}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            尾款微信订单ID: {order.order_info.balance_wx_order_id}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            尾款完成时间: {order.order_info.balance_complete_time}
                        </Typography>
                    </Stack>
                    <Divider variant='fullWidth' light />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' paddingTop={2}>
                        <Typography variant='h6' gutterBottom>
                            车辆信息
                        </Typography>
                    </Stack>
                    <Stack paddingBottom={1}>
                        <Typography variant='body1' gutterBottom>
                            行程日期: {order.arrangements[0].date}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            司机姓名: {driver?.name}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            联系方式: {driver?.mobile_phone}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            车牌号码: {driver?.license_plate_number}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            发车时间: {arrangementInfo?.depart_time}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            起始行程: {scheduleName}
                        </Typography>
                    </Stack>
                    <Divider variant='fullWidth' light />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' paddingTop={2}>
                        <Typography variant='h6' gutterBottom>
                            其他信息
                        </Typography>
                    </Stack>
                    <Stack paddingBottom={1}>
                        <Typography variant='body1' gutterBottom>
                            订单创建时间: {order.order_info.created_time}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            订单修改时间: {order.order_info.last_mod_time}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>关闭</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default OrderInfoDialog;