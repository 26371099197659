import { ConfirmProvider } from "material-ui-confirm";
import { Navigate, Outlet } from "react-router-dom";
import OrderBar from "./OrderBar";


const Order = () => {
    const userId = localStorage.getItem("username");
    if (userId) {
        return (
            <ConfirmProvider>
                <div className="order">
                    <OrderBar />
                    <div style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                        <Outlet />
                    </div>
                </div>
            </ConfirmProvider>
        );
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Order;