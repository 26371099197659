import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IGetAllScheduleRsp } from '../../api/service.type';
import Button from '@mui/material/Button';
import { shuttleService } from '../../api/service';
import { useConfirm } from 'material-ui-confirm';
import { getMessage } from '../../utils/MessageUtils';
import { useAlert } from '../../components/useAlert';

type Props = {
    data?: IGetAllScheduleRsp,
    handleReload: () => void;
}

const ScheduleList = ({ data: data, handleReload }: Props) => {
    const { showAlert } = useAlert();
    const confirm = useConfirm();
    const handleDelete = (id: number) => {
        confirm({ title: '是否删除', confirmationText: '确定', cancellationText: '取消', description: '该车次数据将被删除' })
            .then(() => {
                // 删除记录
                shuttleService.deleteSchedule({
                    id: id
                }).then(({ code, message }) => {
                    if (code === 0) {
                        // 重新加载
                        handleReload();
                    } else {
                        showAlert("删除失败: " + message, 'error')
                    }
                }).catch((err) => {
                    showAlert("删除失败: " + err, 'error')
                })
            }).catch(() => { });
    }

    return (
        data && data.list.length > 0 ?
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">用车类型</TableCell>
                            <TableCell align="center">发车时间</TableCell>
                            <TableCell align="center">出发地</TableCell>
                            <TableCell align="center">目的地</TableCell>
                            <TableCell align="center">操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.list.map((schedule) => {
                            let city = getMessage(schedule.city, 'City');
                            let airport = getMessage(schedule.airport, 'Airport');
                            let useType = getMessage(schedule.use_type, 'UseType');
                            return (
                                <TableRow
                                    key={schedule.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {useType}
                                    </TableCell>
                                    <TableCell align="center">{schedule.depart_time}</TableCell>
                                    <TableCell align="center">{schedule.use_type === "Send" ? city : airport}</TableCell>
                                    <TableCell align="center">{schedule.use_type === "Send" ? airport : city}</TableCell>
                                    <TableCell align="center">
                                        <Button variant="text" onClick={() => handleDelete(schedule.id)}>删除</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            <div>暂无数据</div>
    )
}

export default ScheduleList;