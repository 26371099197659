import { Navigate, Route, Routes } from "react-router-dom";
import Order from "./pages/orderManager";
import NavSideBar from "./pages/NavManager";
import LoginIn from "./pages/loginManager";
import Driver from "./pages/driverManager";
import Schedule from "./pages/scheduleManager";
import Account from "./pages/accountManager";
import OrderDetail from "./pages/orderManager/OrderDetail";
import DailyOrder from "./pages/orderManager/DailyOrder";
import Trip from "./pages/tripManager";

export default function App() {
    return (
        <div className="App">
            <Routes>
                <Route path='/login' Component={LoginIn} />
                <Route path='/*' Component={NavSideBar}>
                    <Route path='order' Component={Order} >
                        <Route path="*" index Component={DailyOrder} />
                        <Route path="detail" Component={OrderDetail} />
                    </Route>
                    <Route path='schedule' Component={Schedule} />
                    <Route path='driver' Component={Driver} />
                    <Route path='trip' Component={Trip} />
                    <Route path="account" Component={Account} />
                    <Route path="*" element={<Navigate to="/order" />} />
                </Route>
            </Routes>
        </div>
    );
}