import { Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import DriverBar from './DriverBar';
import AddDriverDialog, { defaultSeatValue } from "./AddDriverDialog";
import { shuttleService } from "../../api/service";
import { IDriver, IGetAllDriverRsp } from "../../api/service.type";
import DriverList from "./DriverList";
import { ConfirmProvider } from "material-ui-confirm";
import EditDriverDialog from "./EditDriverDialog";

const defaultDriver: IDriver = {
    id: 0,
    name: "",
    mobile_phone: "",
    license_plate_number: "",
    capacity: defaultSeatValue
}

const Driver = () => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [drivers, setDrivers] = useState<IGetAllDriverRsp>();
    const [reloadIndicator, setReloadIndicator] = useState<number>();
    const [editDriver, setEditDriver] = useState<IDriver>();
    const userId = localStorage.getItem("username");
    useEffect(() => {
        if (userId) {
            shuttleService.getAllDrivers().then(({ code, data }) => {
                if (code !== 0 || !data) {
                    return;
                }
                setDrivers(data);
            })
        }
    }, [reloadIndicator]);
    const openAddDialog = () => {
        setOpenAdd(true);
    };
    const closeAddDialog = () => {
        setOpenAdd(false);
    };
    const openEditDialog = () => {
        setOpenEdit(true);
    }
    const closeEditDialog = () => {
        setOpenEdit(false);
    }
    const handleReload = () => {
        setReloadIndicator(Date.now())
    }

    if (userId) {
        return (
            <ConfirmProvider>
                <div className="driver">
                    <DriverBar openAddDialog={openAddDialog} />
                    <div style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                        <DriverList data={drivers} openEditDialog={openEditDialog} handleReload={handleReload} handleEdit={setEditDriver} />
                    </div>
                    <AddDriverDialog
                        isOpen={openAdd}
                        handleClose={closeAddDialog}
                        handleCreate={handleReload}
                    />
                    <EditDriverDialog
                        data={editDriver || defaultDriver}
                        isOpen={openEdit}
                        handleClose={closeEditDialog}
                        handleUpdate={handleReload}
                    />
                </div>
            </ConfirmProvider>
        );
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Driver;