import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DescriptionIcon from '@mui/icons-material/Description';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useLayoutEffect, useState } from 'react';
import { shuttleService } from '../../api/service';
import { useAlert } from '../useAlert';


export default function LeftDrawer() {
    const { showAlert } = useAlert();
    const params = useParams();
    const [selected, setSelected] = useState<string>();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        setSelected(params['*'] || 'order');
    })

    const handleLogout = () => {
        shuttleService.logout().then(({ code, message }) => {
            if (code === 0) {
                showAlert('成功登出')
                localStorage.removeItem('username')
                navigate('/login')
            } else {
                showAlert('登出失败: ' + message, 'error')
            }
        }).catch((err) => {
            showAlert('登出失败: ' + err, 'error')
        })
    }

    const handleClick = (key: string) => {
        setSelected(key)
        navigate('/' + key)
    }

    const list = () => (
        <Box sx={{ width: 200 }}>
            <List>
                <ListItem key='order' disablePadding onClick={() => handleClick('order')}>
                    <ListItemButton selected={selected == 'order'}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary='订单管理' />
                    </ListItemButton>
                </ListItem>
                <ListItem key='schedule' disablePadding onClick={() => handleClick('schedule')}>
                    <ListItemButton selected={selected == 'schedule'}>
                        <ListItemIcon>
                            <DirectionsCarIcon />
                        </ListItemIcon>
                        <ListItemText primary='发车配置' />
                    </ListItemButton>
                </ListItem>
                <ListItem key='driver' disablePadding onClick={() => handleClick('driver')}>
                    <ListItemButton selected={selected == 'driver'}>
                        <ListItemIcon>
                            <AirlineSeatReclineNormalIcon />
                        </ListItemIcon>
                        <ListItemText primary='司机配置' />
                    </ListItemButton>
                </ListItem>
                <ListItem key='trip' disablePadding onClick={() => handleClick('trip')}>
                    <ListItemButton selected={selected == 'trip'}>
                        <ListItemIcon>
                            <AirlineSeatReclineNormalIcon />
                        </ListItemIcon>
                        <ListItemText primary='行程配置' />
                    </ListItemButton>
                </ListItem>
                <ListItem key='account' disablePadding onClick={() => handleClick('account')}>
                    <ListItemButton selected={selected == 'account'}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary='账号设置' />
                    </ListItemButton>
                </ListItem>
                <ListItem key='logout' disablePadding onClick={handleLogout}>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='退出登录' />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Fragment key='left-drawer'>
                <Drawer variant={'permanent'}>
                    {list()}
                </Drawer>
            </Fragment>
        </div>
    );
}
