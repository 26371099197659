import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

type Props = {
    openAddDialog: () => void;
};

const ScheduleBar = ({ openAddDialog: openDialog }: Props) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        发车配置
                    </Typography>
                    <Button color="inherit" onClick={() => openDialog()}>
                        <AddIcon />
                        新增车次
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default ScheduleBar;