import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { shuttleService } from "../../api/service";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getMessage } from "../../utils/MessageUtils";
import { IconButton } from "@mui/material";
import { IDateArrangement, IDriver, IGetAllDriverRsp, IOrderArrangement, IOrderVo } from "../../api/service.type";
import OrderTable from "./OrderTable";
import { useAlert } from "../../components/useAlert";

const resolveOrders = (orders: IOrderArrangement[]): [Array<IDateArrangement>, IOrderVo[]] => {
    let sortedOrders: IOrderVo[] = orders.map(order => {
        order.arrangements.sort((a, b) => {
            if (a.depart_time === b.depart_time) {
                return 0;
            } else if (a.depart_time > b.depart_time) {
                return 1;
            } else {
                return -1;
            }
        });
        return {
            earliest_time: order.arrangements[0].depart_time,
            use_type: order.arrangements[0].use_type,
            order_info: order.order_info,
            arrangements: order.arrangements
        };
    }).sort((a, b) => {
        if (a.earliest_time === b.earliest_time) {
            return 0;
        } else if (a.earliest_time > b.earliest_time) {
            return 1;
        } else {
            return -1;
        }
    });
    let arrangementIdSet = new Set<number>();
    let dateArrangements = new Array<IDateArrangement>();
    sortedOrders.forEach((order) => {
        order.arrangements.forEach((arrangement) => {
            if (!arrangementIdSet.has(arrangement.arrangement_id)) {
                dateArrangements.push(arrangement);
                arrangementIdSet.add(arrangement.arrangement_id);
            }
        });
    });
    dateArrangements.sort((a, b) => {
        if (a.depart_time === b.depart_time) {
            return 0;
        } else if (a.depart_time > b.depart_time) {
            return 1;
        } else {
            return -1;
        }
    });
    return [dateArrangements, sortedOrders]
}

const combineArrangements = (sendDateArrangements: IDateArrangement[], pickupDateArrangements: IDateArrangement[]): [Array<IDateArrangement>, Array<IDateArrangement>] => {
    let sendArrangements = new Array<IDateArrangement>();
    let pickupArrangements = new Array<IDateArrangement>();
    let sendIndex = 0, pickupIndex = 0;
    while (sendIndex < sendDateArrangements.length || pickupIndex < pickupDateArrangements.length) {
        if (sendIndex == sendDateArrangements.length) {
            sendArrangements = sendArrangements.concat(pickupDateArrangements.slice(pickupIndex));
            pickupArrangements = pickupArrangements.concat(pickupDateArrangements.slice(pickupIndex));
            break;
        }
        if (pickupIndex == pickupDateArrangements.length) {
            sendArrangements = sendArrangements.concat(sendDateArrangements.slice(sendIndex));
            pickupArrangements = pickupArrangements.concat(sendDateArrangements.slice(sendIndex));
            break;
        }
        if (sendDateArrangements[sendIndex].depart_time === pickupDateArrangements[pickupIndex].depart_time) {
            sendArrangements.push(sendDateArrangements[sendIndex]);
            pickupArrangements.push(pickupDateArrangements[pickupIndex]);
            sendIndex++;
            pickupIndex++;
        } else if (sendDateArrangements[sendIndex].depart_time < pickupDateArrangements[pickupIndex].depart_time) {
            sendArrangements.push(sendDateArrangements[sendIndex]);
            pickupArrangements.push(sendDateArrangements[sendIndex]);
            sendIndex++;
        } else {
            sendArrangements.push(pickupDateArrangements[pickupIndex]);
            pickupArrangements.push(pickupDateArrangements[pickupIndex]);
            pickupIndex++;
        }
    }
    return [sendArrangements, pickupArrangements]
}

interface OrderState {
    loaded: boolean,
    sendData: IOrderVo[],
    pickupData: IOrderVo[],
    sendArrangements: IDateArrangement[],
    pickupArrangements: IDateArrangement[]
}

const OrderDetail = () => {
    const location = useLocation();
    const date = location.state.date;
    const airport = location.state.airport;
    const city = location.state.city;
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [drivers, setDrivers] = useState<IDriver[]>();
    const [orderData, setOrderData] = useState<OrderState>({
        loaded: false,
        sendData: [],
        pickupData: [],
        sendArrangements: [],
        pickupArrangements: []
    })
    const [reloadIndicator, setReloadIndicator] = useState<number>();

    // 获取广告数据
    useEffect(() => {
        if (!date || !airport || !city) {
            return;
        }

        shuttleService.getDailyOrders({
            date: date,
            airport: airport,
            city: city
        }).then(({ code, data, message }) => {
            if (code === 0) {
                if (data) {
                    let [sendDateArrangements, sendOrders] = resolveOrders(data.send_orders);
                    let [pickupDateArrangements, pickupOrders] = resolveOrders(data.pickup_orders);
                    let [sendArrangements, pickupArrangements] = combineArrangements(sendDateArrangements, pickupDateArrangements);
                    setOrderData({
                        loaded: true,
                        sendData: sendOrders,
                        pickupData: pickupOrders,
                        sendArrangements: sendArrangements,
                        pickupArrangements: pickupArrangements
                    });
                }
            } else {
                showAlert("获取失败: " + message, 'error')
            }
        }).catch((err) => {
            showAlert("获取失败: " + err, 'error')
        })
    }, [reloadIndicator])

    // 获取全部司机信息
    useEffect(() => {
        shuttleService.getAllDrivers().then(({ code, data, message }) => {
            if (code !== 0 || !data) {
                showAlert('获取司机信息失败: ' + message, 'error')
            } else {
                setDrivers(data.list);
            }
        })
    }, [])

    const handleReload = () => {
        setReloadIndicator(Date.now())
    }

    const handleBack = () => {
        navigate('/order', { state: { date: date } })
    }

    const { loaded, sendData, pickupData, sendArrangements, pickupArrangements } = orderData;

    return (
        <div>
            <Stack direction='row' justifyContent="space-between">
                <IconButton
                    aria-label="back"
                    size="small"
                    onClick={handleBack}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Stack direction='row' spacing={20} justifyContent='flex-end'>
                    <Stack direction='row' spacing={2}>
                        <Typography variant="h6" gutterBottom>
                            发车时间
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {date}
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <Typography variant="h6" gutterBottom>
                            机场
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {getMessage(airport, 'Airport')}
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <Typography variant="h6" gutterBottom>
                            城市
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {getMessage(city, 'City')}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            {loaded && drivers ? sendData.length > 0 ? (
                <OrderTable
                    orders={sendData}
                    arrangements={sendArrangements}
                    airport={airport}
                    city={city}
                    useType="Send"
                    drivers={drivers}
                    handleUpdate={handleReload}
                />) : (<div>无送机订单数据</div>) : null}
            {loaded && drivers ? pickupData.length > 0 ? (
                <OrderTable
                    orders={pickupData}
                    arrangements={pickupArrangements}
                    airport={airport}
                    city={city}
                    useType="Pickup"
                    drivers={drivers}
                    handleUpdate={handleReload}
                />) : (<div>无接机订单数据</div>) : null}
        </div>
    );
}

export default OrderDetail;