import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { BootstrapDialog, defaultSeatValue, seats } from "../driverManager/AddDriverDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { shuttleService } from "../../api/service";
import { IDriver } from "../../api/service.type";
import { useConfirm } from "material-ui-confirm";
import { useAlert } from "../../components/useAlert";


type Props = {
    isOpen: boolean;
    data: Map<string, [number, number]>;
    drivers: IDriver[];
    dispatch_index: number;
    handleClose: () => void;
    handleDispatched: () => void;
};

const DispatchCarDialog = ({ isOpen, data, drivers, dispatch_index, handleClose, handleDispatched: handleDispatched }: Props) => {
    const { showAlert } = useAlert();
    const confirm = useConfirm();
    const [capacity, setCapacity] = useState<number | undefined>();
    const [driverList, setDriverList] = useState<IDriver[]>([]);
    const [driverId, setDriverId] = useState<number>();
    const [totalNum, setTotalNum] = useState<number>(0);

    useLayoutEffect(() => {
        let totalNum = 0;
        data.forEach((value, _) => {
            totalNum += value[1]
        })
        setTotalNum(totalNum);
    }, [data])

    useLayoutEffect(() => {
        if (isOpen) {
            setCapacity(7);
            setDriverId(undefined);
        }
    }, [isOpen])

    useEffect(() => {
        let filteredDrivers = drivers.filter((driver) => driver.capacity === capacity);
        setDriverList(filteredDrivers);
    }, [capacity])

    const handleSelectCapacity = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDriverId(undefined)
        setCapacity(Number(event.target.value))
    }

    const handleSelectDriver = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDriverId(Number(event.target.value))
    }

    const dispatchOrders = () => {
        if (!driverId) {
            showAlert('必须选择车辆信息!', 'error')
            return;
        }

        shuttleService.dispatchOrders({
            order_id_list: Array.from(data.keys()),
            arrangement_id: data.values().next().value?.[0],
            dispatch_index: dispatch_index,
            driver_id: driverId
        }).then(({ code, message }) => {
            if (code !== 0) {
                showAlert('分配失败: ' + message, 'error')
            } else {
                handleDispatched()
                handleClose()
            }
        })
    }

    const handleSubmit = () => {
        if (totalNum > 6) {
            showAlert('选中订单人数不能大于6人!', "error");
            return;
        }
        if (capacity === undefined) {
            return;
        }
        if (totalNum > capacity - 1) {
            showAlert('当前选中订单人数大于所选车型载客量，请重新选择车型', "error");
            return;
        }
        if (totalNum < 5 && capacity == 7) {
            confirm({ title: '是否确定分配', confirmationText: '确定', cancellationText: '取消', description: '当前人数无需分配7座车' })
                .then(() => dispatchOrders())
                .catch(() => { })
        } else {
            dispatchOrders();
        }
    }


    return (
        <Fragment>
            <BootstrapDialog
                onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    订单车辆配置
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {Array.from(data.entries()).map((entry) => (
                        <TextField
                            key={entry[0]}
                            required
                            id={entry[0] + "-" + entry[1]}
                            label="订单ID"
                            size="small"
                            defaultValue={entry[0]}
                            InputProps={{ readOnly: true }}
                            fullWidth
                            margin="normal"
                        />
                    ))}
                    <TextField
                        required
                        id="car-capacity"
                        select
                        label="座位数"
                        defaultValue={defaultSeatValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectCapacity}
                    >
                        {seats.map((seat) => (
                            <MenuItem key={seat.label} value={seat.value}>
                                {seat.value}
                            </MenuItem>
                        ))}
                    </TextField>
                    {drivers &&
                        <TextField
                            required
                            id="car-driver"
                            select
                            label="车辆信息"
                            value={driverId || ''}
                            size='small'
                            fullWidth
                            margin='normal'
                            onChange={handleSelectDriver}
                        >
                            {driverList
                                .map((driver) => (
                                    <MenuItem key={driver.id} value={driver.id}>
                                        {driver.name + "-" + driver.license_plate_number}
                                    </MenuItem>
                                ))}
                        </TextField>}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit}>
                        提交
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default DispatchCarDialog;