import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


type Props = {
    openAddDialog: () => void;
};

const DriverBar = ({ openAddDialog: openDialog }: Props) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        司机配置
                    </Typography>
                    <Button color="inherit" onClick={() => openDialog()}>
                        <AddIcon />
                        新增司机
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default DriverBar;