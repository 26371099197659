import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IDriver, IGetAllDriverRsp } from '../../api/service.type';
import Button from '@mui/material/Button';
import { shuttleService } from '../../api/service';
import { useConfirm } from 'material-ui-confirm';
import { useAlert } from '../../components/useAlert';

type Props = {
    data?: IGetAllDriverRsp,
    openEditDialog: () => void;
    handleReload: () => void;
    handleEdit: (driver: IDriver) => void;
}

const DriverList = ({ data, openEditDialog, handleReload, handleEdit }: Props) => {
    const { showAlert } = useAlert();
    const confirm = useConfirm();
    const handleDelete = (id: number) => {
        confirm({ title: '是否删除', confirmationText: '确定', cancellationText: '取消', description: '该司机数据将被删除' })
            .then(() => {
                // 删除记录
                shuttleService.deleteDriver({
                    id: id
                }).then(({ code, message }) => {
                    if (code === 0) {
                        // 重新加载
                        handleReload();
                    } else {
                        showAlert("删除失败" + message, 'error')
                    }
                }).catch((err) => {
                    showAlert("删除失败" + err, 'error')
                })
            }).catch(() => { });
    }

    return (
        data && data.list.length > 0 ?
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">姓名</TableCell>
                            <TableCell align="center">手机号</TableCell>
                            <TableCell align="center">车牌号</TableCell>
                            <TableCell align="center">座位数</TableCell>
                            <TableCell align="center">操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.list.map((driver) => (
                            <TableRow
                                key={driver.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="row">
                                    {driver.name}
                                </TableCell>
                                <TableCell align="center">{driver.mobile_phone}</TableCell>
                                <TableCell align="center">{driver.license_plate_number}</TableCell>
                                <TableCell align="center">{driver.capacity}</TableCell>
                                <TableCell align="center">
                                    <Button variant="text" onClick={() => {
                                        handleEdit({ ...driver });
                                        openEditDialog();
                                    }}>修改</Button>
                                    <Button variant="text" onClick={() => handleDelete(driver.id)}>删除</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            <div>暂无数据</div>
    )
}

export default DriverList;