import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { shuttleService } from '../../api/service';
import Stack from '@mui/material/Stack';
import { useAlert } from '../../components/useAlert';


const AccountModifier = () => {
    const { showAlert } = useAlert();
    const [originUsername, setOriginUsername] = useState<string>();
    const [originPassword, setOriginPassword] = useState<string>();
    const [newUsername, setNewUsername] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeOriginUsername = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setOriginUsername(event.target.value)
    }

    const handleChangeOriginPassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setOriginPassword(event.target.value)
    }
    const handleChangeNewUsername = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNewUsername(event.target.value)
    }
    const handleChangeNewPassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const handleSubmit = () => {
        if (!originUsername || !originPassword || !newUsername || !newPassword) {
            showAlert("字段不能为空!", 'error')
            return;
        }
        setLoading(true);
        shuttleService.modifyAccount({
            origin_username: originUsername,
            origin_password: originPassword,
            new_username: newUsername,
            new_password: newPassword
        }).then(({ code, message }) => {
            if (code !== 0) {
                showAlert('修改失败: ' + message, 'error')
            } else {
                showAlert('修改成功', 'success')
            }
        }).catch((err) => {
            showAlert('修改失败: ' + err, 'error')
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Stack
            sx={{ width: 400 }}
            spacing={5}
            paddingTop={5}
        >
            <TextField
                required
                id="origin-username"
                label="原用户名"
                size='small'
                fullWidth
                margin='normal'
                onChange={handleChangeOriginUsername}
            />
            <TextField
                required
                id="origin-password"
                label="原密码"
                type="password"
                size='small'
                fullWidth
                margin='normal'
                onChange={handleChangeOriginPassword}
            />
            <TextField
                required
                id="new-username"
                label="新用户名"
                size='small'
                fullWidth
                margin='normal'
                onChange={handleChangeNewUsername}
            />
            <TextField
                required
                id="new-password"
                label="新密码"
                type="password"
                size='small'
                fullWidth
                margin='normal'
                onChange={handleChangeNewPassword}
            />
            <LoadingButton variant="contained" loading={loading} onClick={handleSubmit}>修改</LoadingButton>
        </Stack>
    )
}

export default AccountModifier;