let jsMessages = window.jsMessages

export function getMessage(key: any, category: string) {
    if (!jsMessages) {
        return key;
    }

    if (key) {
        if (category) {
            var fullKey = category + '.' + key;
            var msg = jsMessages(fullKey);
            if (msg !== fullKey) {
                return msg;
            }
        }
        return jsMessages(key);
    } else {
        return null;
    }
}