import { ConfirmProvider } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { shuttleService } from "../../api/service";
import TripBar from "./TripBar";
import { IGetAllTripRsp, ITrip } from "../../api/service.type";
import { useAlert } from "../../components/useAlert";
import TripList from "./TripList";
import AddTripDialog from "./AddTripDialog";
import EditTripDialog from "./EditTripDialog";

const defaultTrip: ITrip = {
    id: 0,
    airport: "",
    city: "",
    duration: 0,
    adv_price: 0,
    one_bal_price: 0,
    two_bal_price: 0,
    three_bal_price: 0
}

const Trip = () => {
    const { showAlert } = useAlert();
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [trips, setTrips] = useState<IGetAllTripRsp>();
    const [reloadIndicator, setReloadIndicator] = useState<number>();
    const [editTrip, setEditTrip] = useState<ITrip>();
    const userId = localStorage.getItem("username");
    useEffect(() => {
        if (userId) {
            shuttleService.getAllTrips().then(({ code, data, message }) => {
                if (code !== 0 || !data) {
                    showAlert('获取行程信息失败: ' + message, 'error');
                } else {
                    setTrips(data);
                }
            })
        }
    }, [reloadIndicator]);

    const openAddDialog = () => {
        setOpenAdd(true);
    };
    const closeAddDialog = () => {
        setOpenAdd(false);
    };
    const openEditDialog = () => {
        setOpenEdit(true);
    }
    const closeEditDialog = () => {
        setOpenEdit(false);
    }
    const handleReload = () => {
        setReloadIndicator(Date.now())
    }

    if (userId) {
        return (
            <ConfirmProvider>
                <div className="driver">
                    <TripBar openAddDialog={openAddDialog} />
                    <div style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                        <TripList data={trips} openEditDialog={openEditDialog} handleReload={handleReload} handleEdit={setEditTrip} />
                    </div>
                    <AddTripDialog
                        isOpen={openAdd}
                        handleClose={closeAddDialog}
                        handleCreate={handleReload}
                    />
                    <EditTripDialog
                        data={editTrip || defaultTrip}
                        isOpen={openEdit}
                        handleClose={closeEditDialog}
                        handleUpdate={handleReload}
                    />
                </div>
            </ConfirmProvider>
        );
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Trip;