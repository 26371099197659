import { ConfirmProvider } from "material-ui-confirm";
import { Navigate } from "react-router-dom";
import ScheduleBar from "./ScheduleBar";
import { useEffect, useState } from "react";
import AddScheduleDialog from "./AddScheduleDialog";
import ScheduleList from "./ScheduleList";
import { IGetAllScheduleRsp } from "../../api/service.type";
import { shuttleService } from "../../api/service";

const Schedule = () => {
    const [openAdd, setOpenAdd] = useState(false);
    const [schedules, setSchedules] = useState<IGetAllScheduleRsp>();
    const [reloadIndicator, setReloadIndicator] = useState<number>();
    const userId = localStorage.getItem("username");
    useEffect(() => {
        if (userId) {
            shuttleService.getAllSchedules().then(({ code, data }) => {
                if (code !== 0 || !data) {
                    return;
                }
                setSchedules(data);
            })
        }
    }, [reloadIndicator]);

    const openAddDialog = () => {
        setOpenAdd(true);
    };
    const closeAddDialog = () => {
        setOpenAdd(false);
    };
    const handleReload = () => {
        setReloadIndicator(Date.now())
    }

    if (userId) {
        return (
            <ConfirmProvider>
                <div className="driver">
                    <ScheduleBar openAddDialog={openAddDialog} />
                    <div style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                        <ScheduleList data={schedules} handleReload={handleReload} />
                    </div>
                    <AddScheduleDialog
                        isOpen={openAdd}
                        handleClose={closeAddDialog}
                        handleCreate={handleReload}
                    />
                </div>
            </ConfirmProvider>
        );
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Schedule;