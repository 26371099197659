import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/DemoContainer";
import { useEffect, useState } from "react";
import { IGetArrangementsRsp } from "../../api/service.type";
import { shuttleService } from "../../api/service";
import dayjs, { Dayjs } from "dayjs";
import { getMessage } from "../../utils/MessageUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../components/useAlert";

const DailyOrder = () => {
    const { showAlert } = useAlert();
    const location = useLocation();
    let initDate: string = location.state && location.state.date;
    if (!initDate) {
        let tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        initDate = tomorrowDate.toISOString().slice(0, 10);
    }
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<IGetArrangementsRsp>();
    const [date, setDate] = useState<Dayjs | null>(dayjs(initDate));
    const navigate = useNavigate();

    useEffect(() => {
        if (!date) {
            return;
        }
        shuttleService.getArrangements({
            date: date.toDate().toLocaleDateString()
        }).then(({ code, data, message }) => {
            if (code === 0) {
                setData(data);
            } else {
                showAlert("获取失败: " + message, 'error')
            }
        }).catch((err) => {
            showAlert("获取失败: " + err, 'error')
        }).finally(() => {
            setLoaded(true);
        })
    }, [date])

    const handleQuery = (airport: string, city: string) => {
        navigate('detail', { state: { date: date?.format('YYYY-MM-DD'), airport: airport, city: city } })
    }

    return (
        <div>
            <Stack direction='row' spacing={10} justifyContent='space-between' paddingBottom={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="发车日期"
                            slotProps={{ textField: { size: 'small' } }}
                            value={date} onAccept={(newDate) => setDate(newDate)} />
                    </DemoContainer>
                </LocalizationProvider>
            </Stack>
            {loaded ? data && data.list.length > 0 ? <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">机场</TableCell>
                            <TableCell align="center">城市</TableCell>
                            <TableCell align="center">操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.list.map((arrangement, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="row">
                                    {getMessage(arrangement.airport, 'Airport')}
                                </TableCell>
                                <TableCell align="center">{getMessage(arrangement.city, 'City')}</TableCell>
                                <TableCell align="center">
                                    <Button variant="text" onClick={() => handleQuery(arrangement.airport, arrangement.city)}>查看详情</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : <div>暂无数据</div> : null}
        </div>
    );
}

export default DailyOrder;