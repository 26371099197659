import { Fragment, useEffect, useState } from "react"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BootstrapDialog } from "../driverManager/AddDriverDialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import CloseIcon from '@mui/icons-material/Close';
import { shuttleService } from "../../api/service"
import MenuItem from "@mui/material/MenuItem"
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Dayjs } from "dayjs";
import { useAlert } from "../../components/useAlert";
import { ITrip } from "../../api/service.type";
import { getMessage } from "../../utils/MessageUtils";


const airports = [
    {
        label: 'Ntg',
        name: '南通兴东机场',
        value: 'Ntg',
    },
    {
        label: 'Sha',
        name: '上海虹桥机场',
        value: 'Sha',
    },
    {
        label: 'Pvg',
        name: '上海浦东机场',
        value: 'Pvg',
    },
    {
        label: 'Wux',
        name: '无锡硕放机场',
        value: 'Wux',
    },
    {
        label: 'Ynz',
        name: '盐城南洋机场',
        value: 'Ynz',
    }
];
const defaultAirportValue = 'Ntg';

const useTypes = [
    {
        label: 'Send',
        name: '送机',
        value: 'Send',
    },
    {
        label: 'Pickup',
        name: '接机',
        value: 'Pickup',
    }
]
const defaultUseTypeValue = 'Send';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: () => void;
};

const AddScheduleDialog = ({ isOpen, handleClose, handleCreate }: Props) => {
    const { showAlert } = useAlert();
    const [airport, setAirport] = useState<string>(defaultAirportValue)
    const [trips, setTrips] = useState<ITrip[]>([])
    const [city, setCity] = useState<string | undefined>()
    const [useType, setUseType] = useState<string>(defaultUseTypeValue)
    const [time, setTime] = useState<string | null>()

    useEffect(() => {
        shuttleService.getTripsByAirport({
            airport: airport
        }).then(({ code, data, message }) => {
            if (code !== 0 || !data) {
                showAlert('查询失败: ' + message, 'error')
                setTrips([])
            } else {
                setTrips(data.list);
            }
        }).catch((err) => {
            showAlert('查询失败: ' + err, 'error')
            setTrips([])
        })
    }, [airport])

    const handleSelectAirport = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setAirport(event.target.value)
    }

    const handleSelectCity = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCity(event.target.value)
    }

    const handleSelectUseType = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUseType(event.target.value)
    }

    const handleChangeTime = (value: Dayjs | null) => {
        setTime(value?.format('HH:mm'));
    }

    const handleSubmit = () => {
        if (!airport || !city || !useType || !time) {
            showAlert('必填字段不能为空!', 'error')
            return;
        }
        shuttleService.addSchedule({
            airport: airport,
            city: city,
            use_type: useType,
            depart_time: time
        }).then(({ code, message }) => {
            if (code !== 0) {
                showAlert('创建失败: ' + message, 'error')
            } else {
                handleCreate()
                handleClose()
            }
        }).catch((err) => {
            showAlert('创建失败: ' + err, 'error')
        })
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    新增车次配置
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TextField
                        required
                        id="airport"
                        select
                        label="机场"
                        defaultValue={defaultAirportValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectAirport}
                    >
                        {airports.map((airport) => (
                            <MenuItem key={airport.label} value={airport.value}>
                                {airport.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        id="city"
                        select
                        label="城市"
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectCity}
                    >
                        {trips.map((trip) => (
                            <MenuItem key={trip.city} value={trip.city}>
                                {getMessage(trip.city, 'City')}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        id="use-type"
                        select
                        label="用车类型"
                        defaultValue={defaultUseTypeValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectUseType}
                    >
                        {useTypes.map((useType) => (
                            <MenuItem key={useType.label} value={useType.value}>
                                {useType.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']} >
                            <TimePicker
                                label="发车时刻"
                                ampm={false}
                                onAccept={handleChangeTime}
                                slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
                        </DemoContainer>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit}>
                        提交
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment >
    );
}

export default AddScheduleDialog;