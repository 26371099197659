import { Navigate } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import AccountBar from "./AccountBar";
import AccountModifier from "./AccountModifier";

const Account = () => {
    const userId = localStorage.getItem("username");
    if (userId) {
        return (
            <ConfirmProvider>
                <div className="driver">
                    <AccountBar />
                    <div style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <AccountModifier />
                    </div>
                </div>
            </ConfirmProvider>
        );
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Account;