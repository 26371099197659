import { Fragment, useEffect, useState } from "react";
import { BootstrapDialog } from "../driverManager/AddDriverDialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import { useAlert } from "../../components/useAlert";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { shuttleService } from "../../api/service";


export const airports = [
    {
        label: 'Ntg',
        name: '南通兴东机场',
        value: 'Ntg',
    },
    {
        label: 'Sha',
        name: '上海虹桥机场',
        value: 'Sha',
    },
    {
        label: 'Pvg',
        name: '上海浦东机场',
        value: 'Pvg',
    },
    {
        label: 'Wux',
        name: '无锡硕放机场',
        value: 'Wux',
    },
    {
        label: 'Ynz',
        name: '盐城南洋机场',
        value: 'Ynz',
    }
];
const defaultAirportValue = 'Ntg';

export const cities = [
    {
        label: 'NT',
        name: '南通',
        value: 'NanTong',
    },
    {
        label: 'TZ',
        name: '通州',
        value: 'TongZhou',
    },
    {
        label: 'HM',
        name: '海门',
        value: 'HaiMen',
    },
    {
        label: 'QD',
        name: '启东',
        value: 'QiDong',
    },
    {
        label: 'RG',
        name: '如皋',
        value: 'RuGao',
    },
    {
        label: 'HA',
        name: '海安',
        value: 'HaiAn',
    }
    ,
    {
        label: 'RD',
        name: '如东',
        value: 'RuDong',
    }
]
const defaultCityValue = 'NanTong';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: () => void;
};

const AddDriverDialog = ({ isOpen, handleClose, handleCreate }: Props) => {
    const { showAlert } = useAlert();
    const [airport, setAirport] = useState<string>(defaultAirportValue)
    const [city, setCity] = useState<string>(defaultCityValue)
    const [duration, setDuration] = useState<number | undefined>();
    const [advPrice, setAdvPrice] = useState<number | undefined>();
    const [oneBalPrice, setOneBalPrice] = useState<number | undefined>();
    const [twoBalPrice, setTwoBalPrice] = useState<number | undefined>();
    const [threeBalPrice, setThreeBalPrice] = useState<number | undefined>();

    useEffect(() => {
        if (!isOpen) {
            setDuration(undefined);
            setAdvPrice(undefined);
            setOneBalPrice(undefined);
            setTwoBalPrice(undefined);
            setThreeBalPrice(undefined);
        }
    }, [isOpen])

    const handleSelectAirport = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setAirport(event.target.value)
    }

    const handleSelectCity = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCity(event.target.value)
    }

    const handleSubmit = () => {
        if (!airport || !city || !duration || !advPrice) {
            showAlert('必填字段不能为空!', 'error')
            return;
        }
        if ((oneBalPrice === undefined || oneBalPrice < 0) || (twoBalPrice === undefined || twoBalPrice < 0) || (threeBalPrice === undefined || threeBalPrice < 0)) {
            showAlert('价格字段值为空或值非法!', 'error')
            return;
        }

        shuttleService.addTrip({
            airport: airport,
            city: city,
            duration: duration,
            adv_price: advPrice,
            one_bal_price: oneBalPrice,
            two_bal_price: twoBalPrice,
            three_bal_price: threeBalPrice
        }).then(({ code, message }) => {
            if (code !== 0) {
                showAlert('创建失败: ' + message, 'error')
            } else {
                handleCreate()
                handleClose()
            }
        }).catch((err) => {
            showAlert('创建失败: ' + err, 'error')
        })
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    新增行程配置
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TextField
                        required
                        id="airport"
                        select
                        label="机场"
                        defaultValue={defaultAirportValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectAirport}
                    >
                        {airports.map((airport) => (
                            <MenuItem key={airport.label} value={airport.value}>
                                {airport.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        id="city"
                        select
                        label="城市"
                        defaultValue={defaultCityValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelectCity}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city.label} value={city.value}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        id="duration"
                        type="number"
                        label="预估行程时长"
                        size='small'
                        fullWidth
                        margin='normal'
                        value={duration}
                        onChange={event => setDuration(Number(event.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">min</InputAdornment>,
                        }}
                    />
                    <TextField
                        required
                        id="adv_price"
                        type="number"
                        label="预付款单价"
                        size='small'
                        fullWidth
                        margin='normal'
                        value={advPrice}
                        onChange={event => setAdvPrice(Number(event.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                        }}
                    />
                    <TextField
                        required
                        id="one_bal_price"
                        type="number"
                        label="一人行尾款单价"
                        size='small'
                        fullWidth
                        margin='normal'
                        value={oneBalPrice}
                        onChange={event => setOneBalPrice(Number(event.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                        }}
                    />
                    <TextField
                        required
                        id="two_bal_price"
                        type="number"
                        label="二人行尾款单价"
                        size='small'
                        fullWidth
                        margin='normal'
                        value={twoBalPrice}
                        onChange={event => setTwoBalPrice(Number(event.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                        }}
                    />
                    <TextField
                        required
                        id="three_bal_price"
                        type="number"
                        label="三人行尾款单价"
                        size='small'
                        fullWidth
                        margin='normal'
                        value={threeBalPrice}
                        onChange={event => setThreeBalPrice(Number(event.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit}>
                        提交
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment >
    );
}

export default AddDriverDialog;