import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertColor } from '@mui/material';

type AlertContextType = {
    showAlert: (message: string, severity?: AlertColor) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

type AlertProviderProps = {
    children: ReactNode;
};

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('info');

    const showAlert = useCallback((message: string, severity: AlertColor = 'info') => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    }, []);

    const hideAlert = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={hideAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={severity}
                    onClose={hideAlert}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
};