import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { shuttleService } from '../../api/service';
import { useAlert } from '../../components/useAlert';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const seats = [
    {
        label: 'seat-5',
        value: 5,
    },
    {
        label: 'seat-7',
        value: 7,
    },
];

export const defaultSeatValue = 7;


type Props = {
    isOpen: boolean;
    handleClose: () => void;
    handleCreate: () => void;
};

const AddDriverDialog = ({ isOpen, handleClose, handleCreate }: Props) => {
    const { showAlert } = useAlert();
    const [name, setName] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [licensePlate, setLicensePlate] = useState<string>()
    const [capacity, setCapacity] = useState<number>(7)

    const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPhone(event.target.value)
    }

    const handleChangePlate = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLicensePlate(event.target.value)
    }

    const handleSelect = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCapacity(Number(event.target.value))
    }

    const handleSubmit = () => {
        if (!name || !phone || !licensePlate) {
            showAlert('必填字段为空!', 'error')
            return;
        }
        shuttleService.addDriver({
            name: name,
            mobile_phone: phone,
            license_plate_number: licensePlate,
            capacity: capacity
        }).then(({ code, message }) => {
            if (code !== 0) {
                showAlert('创建失败: ' + message, 'error')
            } else {
                handleCreate()
                handleClose()
            }
        }).catch((err) => {
            showAlert('创建失败: ' + err, 'error')
        })
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    新增司机配置
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TextField
                        required
                        id="driver-name"
                        label="姓名"
                        placeholder="司机姓名"
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleChangeName}
                    />
                    <TextField
                        required
                        id="mobile-phone"
                        label="手机号"
                        placeholder="手机号"
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleChangePhone}
                    />
                    <TextField
                        required
                        id="license_plate_number"
                        label="车牌"
                        placeholder="车牌"
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleChangePlate}
                    />
                    <TextField
                        required
                        id="car-capacity"
                        select
                        label="座位数"
                        defaultValue={defaultSeatValue}
                        size='small'
                        fullWidth
                        margin='normal'
                        onChange={handleSelect}
                    >
                        {seats.map((seat) => (
                            <MenuItem key={seat.label} value={seat.value}>
                                {seat.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit}>
                        提交
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment >
    );
}

export default AddDriverDialog;